export interface InvoiceEndpoints {
  readonly invoices: {
    readonly invoice: string;
    readonly transaction: string;
    readonly pdf: string;
  };
  readonly customer: {
    readonly customerPayable: string;
    readonly transaction: string;
  };
  readonly tokenizationConfiguration: string;
  readonly gatewayConfiguration: string; //todo: should this exist in this interface?
  readonly invoiceConfiguration: string;
  readonly calculateFees: string;
  readonly tokenizeACH: string;
}

export interface SimplePayEndpoints {
  readonly gatewayConfiguration: string;
  readonly simplePayConfiguration: string;
}

export const invoiceEndpoints: InvoiceEndpoints = {
  invoices: {
    invoice: `invoice/`,
    transaction: `${process.env.REACT_APP_IQPRO_SAAS_URL}/gateway/{gatewayId}/invoice/{invoiceId}/transaction`,
    pdf: `${process.env.REACT_APP_IQPRO_SAAS_URL}/invoice/{invoiceToken}/pdf`,
  },
  customer: {
    customerPayable: `${process.env.REACT_APP_IQPRO_SAAS_URL}/invoice/{CustomerPayableToken}/customer?limit={rowsPerPage}&offset={offset}`,
    transaction: `${process.env.REACT_APP_IQPRO_SAAS_URL}/customer/{token}/transaction`,
  },
  invoiceConfiguration: `{token}/gateway/configuration`,
  tokenizationConfiguration: `{token}/tokenization/configuration`,
  gatewayConfiguration: '{token}/gateway/configuration',
  calculateFees: `{token}/transaction/calculatefees`,
  tokenizeACH: `${process.env.REACT_APP_BASYS_API_URL}/vault/api/v1/Tokenize/Ach`,
};

export const simplePayEndpoints: SimplePayEndpoints = {
  gatewayConfiguration: '{token}/gateway/configuration',
  simplePayConfiguration: `${process.env.REACT_APP_SIMPLE_PAY_API_URL}/simplepay/{simplePayId}`,
};
