import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { validationRules } from '../../core/constants/validationRules';
import { Country, State } from 'country-state-city';
import SimpleTextField from './SimplePayTextFields';
import { regexPatterns } from '../../core/constants/regexPatterns';
import { validateAddressField } from '../../core/helpers/formHelpers';
import StateDropDown from '../Global/Address/StateDropDown';
import CountryDropDown from '../Global/Address/CountryDropDown';
import { ISimpleFieldSettings } from '../../core/interfaces/simplepay.interface';

type SimplePaymentAddressProps = {
  type: string;
  fieldSettings: ISimpleFieldSettings;
  handleStateChange?: (state: string) => void;
};

const SimplePaymentAddress = ({ type, fieldSettings, handleStateChange }: SimplePaymentAddressProps) => {
  const methods = useFormContext();
  const countries = Country.getAllCountries();
  const [states, setStates] = useState(State.getStatesOfCountry('US'));
  const watchReceipt = methods.watch('receipt');

  const onCountryChange = (country: string) => {
    setStates(State.getStatesOfCountry(country));
    methods.setValue(`${type}.state`, '');
    if (handleStateChange) {
      handleStateChange('');
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = removeNonNumeric(value);
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const phoneTransform = {
    input: (value: string) => formatPhoneNumber(value),
    output: (value: string) => removeNonNumeric(value).slice(0, 10),
  };

  const removeNonNumeric = (str: string) => str.replace(regexPatterns.nonNumeric, '');

  return (
    <div className='address-wrapper'>
      <div className='two-column-row'>
        <SimpleTextField
          registration={methods.register(`${type}.firstName`, {
            required: true,
            maxLength: validationRules.maxLength50,
          })}
          error={validateAddressField(methods.formState.errors, type, 'firstName')}
          label={'First Name'}
          id={'firstName'}
          asterisk={true}
        />
        <SimpleTextField
          registration={methods.register(`${type}.lastName`, {
            required: true,
            maxLength: validationRules.maxLength50,
          })}
          error={validateAddressField(methods.formState.errors, type, 'lastName')}
          label={'Last Name'}
          id={'lastName'}
          asterisk={true}
        />
      </div>
      {fieldSettings.companyIsIncluded && (
        <SimpleTextField
          registration={methods.register(`${type}.company`, {
            required: fieldSettings.companyIsRequired,
            maxLength: validationRules.maxLength50,
          })}
          error={validateAddressField(methods.formState.errors, type, 'company')}
          label={fieldSettings.companyLabelText || 'Company'}
          id={'company'}
          asterisk={fieldSettings.companyIsRequired}
        />
      )}
      {fieldSettings.addressIsIncluded && (
        <>
          <div className='two-column-row'>
            <SimpleTextField
              registration={methods.register(`${type}.addressLine1`, { required: fieldSettings.addressIsRequired })}
              error={validateAddressField(methods.formState.errors, type, 'addressLine1')}
              label={'Address Line 1'}
              id={'addressLine1'}
              asterisk={fieldSettings.addressIsRequired}
            />
            <SimpleTextField
              registration={methods.register(`${type}.addressLine2`)}
              error={validateAddressField(methods.formState.errors, type, 'addressLine2')}
              label={'Address Line 2'}
              id={'addressLine2'}
            />
          </div>
          <div className='country-city-state-zip-row'>
            <CountryDropDown
              countries={countries}
              type={type}
              isRequired={fieldSettings.addressIsRequired}
              onCountryChange={onCountryChange}
            />
            <SimpleTextField
              registration={methods.register(`${type}.city`, { required: fieldSettings.addressIsRequired })}
              error={validateAddressField(methods.formState.errors, type, 'city')}
              label={'City'}
              id={'city'}
              asterisk={fieldSettings.addressIsRequired}
            />
            <StateDropDown
              states={states}
              type={type}
              isRequired={fieldSettings.addressIsRequired}
              handleStateChange={handleStateChange}
            />
            <SimpleTextField
              registration={methods.register(`${type}.postalCode`, {
                required: fieldSettings.addressIsRequired,
                maxLength: 10,
                minLength: 5,
                pattern: regexPatterns.postalCode,
              })}
              error={validateAddressField(methods.formState.errors, type, 'postalCode')}
              label={'Postal Code'}
              id={'postalCode'}
              asterisk={fieldSettings.addressIsRequired}
            />
          </div>
        </>
      )}
      <div className='two-column-row'>
        {fieldSettings.emailIsIncluded && (
          <SimpleTextField
            registration={methods.register(`${type}.email`, {
              pattern: validationRules.email,
              required: fieldSettings.emailIsRequired || watchReceipt,
            })}
            error={validateAddressField(methods.formState.errors, type, 'email')}
            label={'Email'}
            id={'email'}
            asterisk={fieldSettings.emailIsRequired || watchReceipt}
          />
        )}
        {fieldSettings.phoneIsIncluded && (
          <div className='field-wrapper'>
            <label>
              Phone
              {fieldSettings.phoneIsRequired && <div className='requiredAsterisk'>*</div>}
            </label>
            <FormControl {...methods.register(`${type}.phone`, { required: fieldSettings.phoneIsRequired })}>
              <Controller
                name={`${type}.phone`}
                control={methods.control}
                render={({ field }) => (
                  <TextField
                    error={validateAddressField(methods.formState.errors, type, 'phone')}
                    onChange={(e) => {
                      field.onChange(phoneTransform.output(e.target.value));
                    }}
                    value={phoneTransform.input(field.value)}
                  />
                )}
              />
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimplePaymentAddress;
