import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import successDark from '../../assets/images/payment/payment-success.mp4';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { useFormContext } from 'react-hook-form';
import { ISimplePayConfiguration } from '../../core/interfaces/simplepay.interface';
import { ISurchargeResponse } from '../../core/interfaces';
import { formatTransactionCurrency } from '../../core/helpers/stringHelpers';

type SimplePaymentSuccessProps = {
  token?: string;
  transactionId: string;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
  simpleConfiguration: ISimplePayConfiguration;
  surchargeResponse: ISurchargeResponse | null;
};
const SimplePaymentSuccess = ({
  transactionId,
  setIsPaid,
  simpleConfiguration,
  surchargeResponse,
}: SimplePaymentSuccessProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const methods = useFormContext();

  const calculateTotal = (base: number, surcharge: number) => {
    return formatTransactionCurrency(base + surcharge);
  };

  return (
    <div className='simple-success-wrapper'>
      <Backdrop sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', zIndex: 9000 }} open={openBackdrop}>
        <VideoPlayer videoSource={successDark} />
      </Backdrop>
      <div>
        <div className='thanks-headline'>Thank you for your payment</div>
        {methods.getValues('billing.email') &&
          methods.getValues('receipt') && ( //TODO: should we validate that the receipt is sent?
            <div>
              A receipt for this transaction was mailed to {methods.getValues('billing.email')} for your records
            </div>
          )}
        <div className='success-amount'>
          {surchargeResponse?.isSurchargeable
            ? calculateTotal(+methods.getValues().paymentAmount, surchargeResponse.surchargeAmount)
            : methods.getValues('paymentAmount')}
        </div>
        <div className='image-wrapper'>
          <VideoPlayer videoSource={successDark} />
        </div>
        <div className='transaction-id'>Transaction ID: {transactionId}</div>
        <button
          className='primary'
          onClick={(e) => {
            e.stopPropagation();
            methods.reset();
            setIsPaid(false);
          }}
          style={{
            backgroundColor: simpleConfiguration.primaryColor,
          }}
        >
          Make Another Payment
        </button>
      </div>
    </div>
  );
};

export default SimplePaymentSuccess;
