import axios, { AxiosResponse } from 'axios';
import { simplePayEndpoints, invoiceEndpoints } from '../../core/constants/endpoints';
import { IHTTPInterface, ISurchargeResponse, ITokenizerConfig } from '../../core/interfaces/index';
import { stringFormat } from '../Global/Tokenizer/tokenizerHelpers';
import { createRef, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import SimplePaymentInformation from './SimplePaymentInformation';
import SimplePaymentAddress from './SimplePaymentAddress';
import SimplePaymentAmount from './SimplePaymentAmount';
import SimplePaymentAdditionalInfo from './SimplePaymentAdditionalInfo';
import SimplePaymentHeader from './SimplePaymentHeader';
import {
  IGatewayConfiguration,
  ISimpleFieldSettings,
  ISimplePayConfiguration,
} from '../../core/interfaces/simplepay.interface';
import SimplePaymentError from './SimplePaymentError';
import SimplePaymentSuccess from './SimplePaymentSuccess';
import { secCode } from '../Global/Tokenizer/IqProACHTokenizer';
import { ILayout, LayoutContext } from '../../contexts/LayoutContext';

export interface ITokenInterface {
  token: string | undefined;
  expirationDate?: string | undefined;
  maskedNumber?: string;
  paymentType: string;
}

const SimplePay = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [tokenConfiguration, setTokenConfiguration] = useState<ITokenizerConfig | null>(null);
  const [simpleConfiguration, setSimpleConfiguration] = useState<ISimplePayConfiguration | null>(null);
  const [gatewayConfiguration, setGatewayConfiguration] = useState<IGatewayConfiguration | null>(null);
  const [errorToken, setErrorToken] = useState<number | null>(null);
  const methods = useForm();
  const theme = useTheme();
  const layoutContext = useContext(LayoutContext);
  const recaptcha = createRef<ReCAPTCHA>();
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [errorBanner, setErrorBanner] = useState<string>('');
  const [errorCount, setErrorCount] = useState<number>(0);
  const { isValid } = methods.formState;
  const [bin, setBin] = useState<string>('');
  const [surchargeState, setSurchargeState] = useState<string>('');
  const [activeTab, setActiveTab] = useState(1);
  const [fieldSettings, setFieldSettings] = useState<ISimpleFieldSettings | null>(null);
  const [surchargeResponse, setSurchargeResponse] = useState<ISurchargeResponse | null>(null);
  const [transactionId, setTransactionId] = useState<string>('');

  //following are hardcoded pending more backend changes
  const cardProcessorId = '78ab4c7d-18b4-44c3-919d-202ff5954019'; //6d9d82d6-8bf5-4601-8aca-6f078faa9314
  const achProcessorId = '2aeeeb97-0d53-4db5-9052-a184a771042c'; //9b0bd677-40f8-452e-b621-cfb82f3c4806

  const retrieveTokenizerConfiguration = () => {
    const url: string = stringFormat(invoiceEndpoints.tokenizationConfiguration, [token!]);
    axios
      .get(url, { headers: { Authorization: token } })
      .then((tokenResponse: AxiosResponse<IHTTPInterface>) => {
        setTokenConfiguration(tokenResponse?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setErrorToken(error.response.status);
      });
  };

  const retrieveSimplePayConfiguration = () => {
    const simplePayId = '64c3df50b994f1b723708b68';

    //TODO: temporary hardcoding, this endpoint will be updated
    const url: string = stringFormat(simplePayEndpoints.simplePayConfiguration, [simplePayId!]) + '?distinctId=100';
    axios
      .get(url, { headers: { Authorization: process.env.REACT_APP_SIMPLE_PAY_API_KEY } })
      .then((simpleResponse: AxiosResponse<IHTTPInterface>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const responseBody: any = simpleResponse?.data;
        /*
          below: edit incoming data for testing -- soon this data will be coming from SaaS
        */
        responseBody.simplePaySettings.hasBanner = true;
        responseBody.simplePaySettings.enlargeLogo = false;
        responseBody.simplePaySettings.formBackgroundColor = ''; //#d4effa
        responseBody.simplePaySettings.primaryColor = ''; //#189ad0
        responseBody.simplePaySettings.backgroundColor = '';
        responseBody.simplePaySettings.bannerType = 'success';
        responseBody.simplePaySettings.bannerMessage = 'This is a banner';
        responseBody.simplePaySettings.hasBanner = true;

        setSimpleConfiguration(responseBody?.simplePaySettings); //simpleResponse?.data?.simplePaySettings doesn't work since it doesn't exist in interface
      })
      .catch((error) => {
        console.log(error);
        setErrorToken(error.response.status);
      });
  };

  const handlePayment = (tokenPayload: ITokenInterface) => {
    if (isValid) {
      recaptcha.current?.executeAsync().then(() => {
        setErrorBanner('');
        const addresses = formatAddress();
        const payload = {
          transactionPaymentMethod: {
            ...(tokenPayload.paymentType === 'card' && {
              card: {
                cardToken: tokenPayload.token,
                maskedNumber: tokenPayload.maskedNumber,
                expirationDate: tokenPayload?.expirationDate || null,
              },
            }),
            ...(tokenPayload.paymentType === 'ach' && {
              ach: {
                achToken: tokenPayload.token,
                secCode: secCode[2],
              },
            }),
          },
          transactionRemit: {
            baseAmount: methods.getValues('paymentAmount'),
            currencyCode: 'USD',
          },
          transactionAddress: addresses,
          emailReceipt: false,
          emailAddress: methods.getValues('billing.email'),
          processorId: tokenPayload.paymentType === 'card' ? cardProcessorId : achProcessorId,
          customFields: [],
        };
        if (methods.getValues('receipt')) {
          payload.emailReceipt = methods.getValues('receipt');
        }
        createTransaction(payload);
      });
    } else {
      setErrorBanner('Please fill all required fields.');
      setErrorCount(errorCount + 1);
    }
  };

  const createTransaction = (payload: object) => {
    setOpenBackdrop(true);
    const url: string = token + '/transaction';
    axios
      .post(url, payload, { headers: { Authorization: token } })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        if (response?.data?.data?.responseCode !== 400 && response?.data?.data?.response.toLowerCase() !== 'declined') {
          setTransactionId(response?.data?.data?.transactionId);
          setIsPaid(true);
        } else {
          setErrorBanner('Transaction Declined: ' + response?.data?.data?.processorResponseText);
          setErrorCount(errorCount + 1);
        }
      })
      .catch(() => {
        setErrorBanner(
          'An error has prevented your payment from processing. Please check that your information is correct and try again.'
        );
        setErrorCount(errorCount + 1);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  //TODO: in invoicing, this works by forcing address to be US. Does this make sense in this case?
  const formatAddress = () => {
    const addresses = [];
    const billing = methods.getValues('billing');
    billing.isBilling = true;
    addresses.push(billing);
    return addresses;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStateChange = (state: string) => {
    setSurchargeState(state);
  };

  const retrieveGatewayConfiguration = () => {
    const url: string = stringFormat(simplePayEndpoints.gatewayConfiguration, [token!]);
    axios
      .get(url, { headers: { Authorization: token } })
      .then((tokenResponse: AxiosResponse<IHTTPInterface>) => {
        setGatewayConfiguration(tokenResponse?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setErrorToken(error.response.status);
      });
  };

  const calculatePaymentType = (hasCardForm: boolean, hasAchForm: boolean) => {
    if (hasCardForm) {
      return hasAchForm ? 'BOTH' : 'CARD';
    } else {
      return 'ACH';
    }
  };

  useEffect(() => {
    retrieveTokenizerConfiguration();
    retrieveGatewayConfiguration();
  }, []); //empty array arg causes this to act like componentDidMount()

  useEffect(() => {
    retrieveSimplePayConfiguration();
  }, [gatewayConfiguration]);

  useEffect(() => {
    if (simpleConfiguration) {
      if (activeTab === 1) {
        setFieldSettings(simpleConfiguration.cardFields);
      } else {
        setFieldSettings({
          ...simpleConfiguration.achFields,
          addressIsIncluded: true,
          addressIsRequired: true,
          phoneIsIncluded: true,
          phoneIsRequired: true,
          emailIsIncluded: true,
          emailIsRequired: true,
        });
      }
    }
  }, [activeTab, simpleConfiguration]);

  useEffect(() => {
    const paymentType = calculatePaymentType(
      simpleConfiguration?.hasCardForm || false,
      simpleConfiguration?.hasAchForm || false
    );
    setActiveTab(paymentType === 'BOTH' || paymentType === 'CARD' ? 1 : 2);
    const root = document.documentElement;
    root?.style.setProperty('--background-color', simpleConfiguration?.backgroundColor || '');
    const layout: ILayout = {
      header: simpleConfiguration?.logo || '',
      footer: simpleConfiguration?.logo || '',
      isHeaderCentered: simpleConfiguration?.enlargeLogo,
    };
    layoutContext?.setLayout(layout);
  }, [simpleConfiguration]);

  return (
    <div className='simplepay'>
      <FormProvider {...methods}>
        <form>
          <div>
            <Backdrop sx={{ color: '#fff', zIndex: 9000, opacity: 0.5 }} open={openBackdrop}>
              <CircularProgress color='inherit' />
            </Backdrop>
            {tokenConfiguration !== null &&
            gatewayConfiguration !== null &&
            simpleConfiguration !== null &&
            fieldSettings !== null ? (
              <>
                {isPaid ? (
                  <SimplePaymentSuccess
                    transactionId={transactionId}
                    setIsPaid={setIsPaid}
                    simpleConfiguration={simpleConfiguration}
                    surchargeResponse={surchargeResponse}
                  />
                ) : (
                  <>
                    <SimplePaymentHeader gatewayName={simpleConfiguration?.merchantName} />
                    <div className='main-row'>
                      <div className='left-column'>
                        <div style={{ marginBottom: '48px' }}>
                          <SimplePaymentAmount
                            simpleConfiguration={simpleConfiguration!}
                            bin={bin}
                            simpleToken={token!}
                            surchargeState={surchargeState}
                            cardProcessorId={cardProcessorId}
                            surchargeResponse={surchargeResponse}
                            setSurchargeResponse={setSurchargeResponse}
                          />
                        </div>
                        <SimplePaymentInformation
                          tokenConfiguration={tokenConfiguration!}
                          simpleConfiguration={simpleConfiguration!}
                          fieldSettings={fieldSettings!}
                          simpleToken={token!}
                          handlePayment={handlePayment}
                          paymentType={calculatePaymentType(
                            simpleConfiguration?.hasCardForm,
                            simpleConfiguration?.hasAchForm
                          )}
                          errorCount={errorCount}
                          errorMessage={errorBanner}
                          gatewayName={simpleConfiguration?.merchantName}
                          setBin={setBin}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                        />
                      </div>
                      <div className='right-column'>
                        {simpleConfiguration?.hasBanner && simpleConfiguration?.bannerMessage && (
                          <div
                            className={
                              'message-banner' +
                              (simpleConfiguration?.bannerType == 'success' ? ' banner-type-success' : '') +
                              (simpleConfiguration?.bannerType == 'dangerous' ? ' banner-type-dangerous' : '') +
                              (simpleConfiguration?.bannerType == 'warning' ? ' banner-type-warning' : '') +
                              (simpleConfiguration?.bannerType == 'regular' ? ' banner-type-regular' : '')
                            }
                          >
                            {simpleConfiguration?.bannerMessage}
                          </div>
                        )}
                        <div className='address-container'>
                          <h3>Billing Information</h3>
                          <SimplePaymentAddress
                            type={'billing'}
                            fieldSettings={fieldSettings!}
                            handleStateChange={handleStateChange}
                          />
                        </div>
                        <div className='additional-info-container'>
                          <h3>
                            {(fieldSettings?.orderIdIsIncluded ||
                              fieldSettings?.poNumberIsIncluded ||
                              fieldSettings?.noteIsIncluded) &&
                              (fieldSettings?.additionalInfoHeaderText || 'Additional Information')}
                          </h3>
                          <SimplePaymentAdditionalInfo fieldSettings={fieldSettings!} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              errorToken && <SimplePaymentError errorCode={errorToken} />
            )}
          </div>
        </form>
        <ReCAPTCHA
          ref={recaptcha}
          theme={theme.palette.mode}
          size='invisible'
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        />
      </FormProvider>
    </div>
  );
};
export default SimplePay;
