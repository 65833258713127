import { useState, useRef, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, ITokenizerConfig } from '../../core/interfaces';
import { ITokenInterface } from './SimplePay';
import CheckIcon from '@mui/icons-material/Check';
import TokenExComponent from '../Global/Tokenizer/TokenEx';
import IqProACHTokenizer from '../Global/Tokenizer/IqProACHTokenizer';
import { useFormContext } from 'react-hook-form';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Checkbox, FormControlLabel, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import { ISimpleFieldSettings, ISimplePayConfiguration } from '../../core/interfaces/simplepay.interface';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: '16px',
  },
});

type SimplePaymentProps = {
  tokenConfiguration: ITokenizerConfig;
  simpleConfiguration: ISimplePayConfiguration;
  fieldSettings: ISimpleFieldSettings;
  handlePayment: (payload: ITokenInterface) => void;
  errorCount: number;
  errorMessage: string;
  simpleToken: string | undefined;
  paymentType: string;
  gatewayName: string;
  setBin: React.Dispatch<React.SetStateAction<string>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

const SimplePayPaymentInformation = ({
  tokenConfiguration,
  simpleConfiguration,
  fieldSettings,
  handlePayment,
  simpleToken,
  paymentType,
  errorCount,
  errorMessage,
  gatewayName,
  setBin,
  activeTab,
  setActiveTab,
}: SimplePaymentProps) => {
  const methods = useFormContext();
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState<ITokenInterface | null>(null);
  const [disableButton, setDisabledButton] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const achRef = useRef<any>();
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const handleSubmission = async (event: React.SyntheticEvent) => {
    setDisabledButton(true);
    event.preventDefault();
    methods.trigger();

    if (paymentType === 'BOTH') {
      activeTab === 1 ? handleCardPayment() : handleACHPayment();
    } else {
      paymentType === 'CARD' ? handleCardPayment() : handleACHPayment();
    }
  };

  const handleCardPayment = () => {
    cardRef.current!.validate();
  };

  const handleACHPayment = () => {
    achRef.current!.validate();
  };

  const handleTokenExValid = (value: string | null) => {
    if (value) setBin(value);
  };

  const handleACHSubmit = (value: string) => {
    setToken({
      token: value,
      paymentType: 'ach',
    });
  };

  const handleACHError = () => {
    setDisabledButton(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleTokenExSubmit = (value: Card, exp: string | null) => {
    setToken({
      token: value.token,
      expirationDate: exp || '',
      maskedNumber: value.firstSix + '******' + value.lastFour,
      paymentType: 'card',
    });
  };

  const handleTokenExError = () => {
    setDisabledButton(false);
  };

  const formatPaymentMethods = (acceptedCards: Array<string>): string => {
    let s = '';
    if (simpleConfiguration.hasCardForm) {
      s = acceptedCards.join(', ');
      if (simpleConfiguration.hasAchForm) s += ', ACH';
    } else if (simpleConfiguration.hasAchForm) s = 'ACH';
    return s;
  };

  useEffect(() => {
    if (token) {
      handlePayment(token);
    }
  }, [token]);

  useEffect(() => {
    if (errorCount > 0) {
      setDisabledButton(false);
    }
  }, [errorCount]);

  console.log(activeTab);

  return (
    <div className='payment-info-container'>
      <div
        className={'payment-section'}
        style={{
          backgroundColor: isDarkTheme ? '#121212' : simpleConfiguration.formBackgroundColor || '#d4effa',
          backgroundImage: isDarkTheme
            ? 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))'
            : 'none',
        }}
      >
        <div className='payment-section-header'>
          <h2>Your {paymentType !== 'BOTH' && paymentType === 'CARD' ? 'Card' : 'Bank'} Payment Information</h2>
          <StyledToolTip title={formatPaymentMethods(simpleConfiguration.acceptedCards)} placement='right' arrow>
            <HelpOutlineIcon sx={{ marginLeft: '5px' }} />
          </StyledToolTip>
        </div>
        {paymentType === 'BOTH' && (
          <Tabs
            className='primary'
            value={activeTab}
            onChange={handleTabChange}
            aria-label='basic tabs example'
            sx={{
              '.MuiButtonBase-root.MuiTab-root.Mui-selected[type="button"][role="tab"]': {
                backgroundColor: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.primaryColor || theme.palette.secondary.main,
              },
            }}
          >
            <Tab iconPosition='start' icon={<CheckIcon />} value={1} label='Card' />
            <Tab iconPosition='start' icon={<CheckIcon />} value={2} label='Bank Account' />
          </Tabs>
        )}
        <div hidden={activeTab !== 1}>
          <div id='tokenizer-card'></div>
          {tokenConfiguration?.iframeConfiguration?.iqProV2 && (
            <TokenExComponent
              tokenConfig={tokenConfiguration!.iframeConfiguration!.iqProV2}
              handleSubmit={handleTokenExSubmit}
              handleError={handleTokenExError}
              tokenizerRef={cardRef}
              validCard={handleTokenExValid}
              iqProToken={simpleToken}
            />
          )}
        </div>
        <div hidden={activeTab !== 2}>
          {tokenConfiguration.achTokenizer === 'IQPROV2' ? (
            <div>
              {activeTab === 2 && (
                <IqProACHTokenizer
                  handleError={handleACHError}
                  SECCode={2}
                  iqProToken={simpleToken}
                  tokenizerRef={achRef}
                  handleSubmit={handleACHSubmit}
                />
              )}
            </div>
          ) : (
            <div id='tokenizer-ach'></div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            disabled={disableButton}
            type='submit'
            onClick={handleSubmission}
            className='primary'
            style={{
              backgroundColor: isDarkTheme
                ? theme.palette.secondary.main
                : simpleConfiguration.primaryColor || theme.palette.secondary.main,
            }}
          >
            Submit Payment
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className='error-banner'>
          <ErrorOutlineIcon /> {errorMessage}
        </div>
      )}
      <p className='disclaimer'>
        By clicking "Submit Payment" you authorize {gatewayName} to charge your Payment Method
      </p>
      {fieldSettings.emailIsIncluded && (
        <div className='email-receipt'>
          <FormControlLabel
            sx={{
              '.MuiButtonBase-root': {
                color: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.primaryColor || theme.palette.secondary.main,
              },
              '.MuiButtonBase-root.Mui-checked': {
                color: isDarkTheme
                  ? theme.palette.secondary.main
                  : simpleConfiguration.primaryColor || theme.palette.secondary.main,
              },
            }}
            control={<Checkbox {...methods.register('receipt')} />}
            label='Email a copy of this receipt'
          />
        </div>
      )}
    </div>
  );
};

export default SimplePayPaymentInformation;
